module.exports = function() {

    var modalUtil = {};

    modalUtil.show = function ($url, $html=false) {
        $("#Modal").modal();
        $('#Modal .modal-content').html(
            '<div class="modal-header">' +
            '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> ' +
            '<h4 class="modal-title">Carregando...</h4> ' +
            '</div>' +
            '<div class="modal-body loading text-center"> ' +
            '<i class="fa fa-spin fa-refresh"></i>' +
            '</div>' +
            '<div class="modal-footer"> ' +
            '<button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button> ' +
            '</div>'
        );

        if($html) {
            $('#Modal .modal-content').html($url);
        } else {
            $('#Modal .modal-content').load($url, function(response, status, xhr) {
                if(status == 'error') {
                    $('#Modal .modal-content').html(
                        '<div class="modal-header">' +
                        '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> ' +
                        '<h4 class="modal-title">Erro</h4> ' +
                        '</div>' +
                        '<div class="modal-body loading text-center"> ' +
                        '<p class="text-red">Ocorreu um erro na sua solicitação.</p>' +
                        '</div>' +
                        '<div class="modal-footer"> ' +
                        '<button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button> ' +
                        '</div>'
                    );
                }
            });
        }
    }

    return modalUtil;
}