module.exports = function() {

    var alerts = {};

    alerts.showAlert = function ($class, $mensagem, $callback=null, $id=null) {

        if($class == 'success') {
            iziToast.success({
                title: 'OK',
                message: $mensagem,
                position: 'topRight',
            });
        }

        if($class == 'error') {
            iziToast.error({
                title: 'ERRO   ',
                message: $mensagem,
                position: 'topRight',
            });
        }

        if($class == 'warning') {
            iziToast.warning({
                title: 'ALERTA',
                message: $mensagem,
                position: 'topRight',
            });
        }

        if($class == 'confirm') {
            iziToast.question({
                timeout: 20000,
                close: false,
                overlay: true,
                toastOnce: true,
                id: 'question',
                zindex: 999,
                title: '',
                message: $mensagem,
                position: 'center',
                color: 'red',
                icon: '',
                buttons: [
                    ['<button><b>SIM</b></button>', function (instance, toast) {
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                        if($callback) {
                            $callback($id);
                        }
                    }, true],
                    ['<button>NÃO</button>', function (instance, toast) {
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    }],
                ],
                onClosed: function(instance, toast, closedBy){
                    //console.info('Closed | closedBy: ' + closedBy);
                }
            });
        }

    }

    return alerts;
}